<template>
    <main>
        <page-header-compact>
            Resultados de Orden de Trabajo
        </page-header-compact>

        <div class="container-fluid px-4">
            <div class="card card-icon mb-2">
                <div class="row no-gutters">
                    <div class="col-auto card-icon-aside bg-light">
                        <i class="fa fa-flask fa-fw"></i>
                    </div>
                    <div class="col">
                        <div class="card-body">
                           <div class="row">
                                <div class="col-lg-8">
                                    <div class="form-row">
                                        <label class="form-label">Fecha</label>
                                        <input
                                        type="date"
                                        class="form-control form-control-sm"
                                        v-model="fecha"

                                    />
                                    </div>
                                </div>
                                <div class="col-lg-4">
                                    <div class="form-row mt-4 py-2">
                                        <button class=" btn btn-success btn-sm" @click="getOrdenLaboratorio();"><i class="fa fa-search fa-fw"></i>Buscar</button>
                                    </div>
                                </div>
                           </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
      


            <div class="card">
                <div class="card-body">

                    <data-grid :data="pagination"
                           @changePage="changePage($event)"
                           @perPageChange="perPageChange($event)"
                           @search="search($event)">
                            <template #grid>
                                <grid-table>
                                    <template #head>
                                        <grid-cell-header class="bg-light"  v-for="(h,i) in headers" :key="`h_${i}`">{{h}}</grid-cell-header>
                                    </template>
                                    <template #body>
                                        <grid-row v-for="(item, i) in pagination.data" :key="`d_${i}`">
                                            <grid-cell class="text-center small">
                                                {{item.id}}
                                            </grid-cell>
                                            <grid-cell class="text-center small">
                                              ({{ item.usuario.documento }})  {{item.usuario.nombre_1}} {{item.usuario.nombre_2}} {{item.usuario.apellido_1}} {{item.usuario.apellido_2}}
                                            </grid-cell>
                                            <grid-cell class="text-center small">
                                                {{item.id_orden}}
                                            </grid-cell>
                                            <grid-cell class="text-center small">
                                                {{item.fecha}}
                                            </grid-cell>
                                           <grid-cell>

                                                <div class="form-group ">
                                                   
                                                    <div v-if="item.resultado.noValidados>0">
                                                        <span class="badge badge-warning mx-2 badge-marketing text-warning-soft">Examenes sin válidar</span>
                                                        
                                                    </div>
                        
                                                    <span  v-else>
                                                        <button class="btn btn-outline-primary btn-sm"  @click="imprimir(item.resultado.id)" title="Imprimir"> <i class="fas fa-print fa-fw"></i></button>
                                                    </span>
                                                            
                                                </div> 


                                           </grid-cell>
                                         

                                        </grid-row>
                                    </template>
                                </grid-table>
                            </template>
    
                    </data-grid>

          
                </div>
            </div>
   
    </main>
</template>
<script>
import PageHeaderCompact from "../../../../components/layouts/content/page-header-compact";
import DataGrid from "../../../../components/common/utilities/DataGrid/DataGrid";
import GridTable from "../../../../components/common/utilities/DataGrid/GridTable";
import GridCellHeader from "../../../../components/common/utilities/DataGrid/GridCellHeader";
import GridRow from "../../../../components/common/utilities/DataGrid/GridRow";
import GridCell from "../../../../components/common/utilities/DataGrid/GridCell";

import Toast from '../../../../components/common/utilities/toast';

//import { required } from "vuelidate/lib/validators";
import ordenTrabajoService from '../../../../services/ordenTrabajoService';

export default {
    components: {
        PageHeaderCompact,GridCell, GridRow, GridCellHeader, GridTable, DataGrid
    },
    data(){
        return {
            ordenResultados:[],
            headers : [
                '#','Usuario','No. Orden clinica','Fecha',''
           ],
           fecha:'',
    
           pagination : {
               data : [],
               params:{
                fecha:'',
           

            },
               current_page : 1,
               page : 1,
               per_page : 5,
               search : '',
               total: '',
           }
        }
    },
          /*
    validations() {

  
      return {
        fecha: {required},
      };

      
    },
    */
    methods : {
        async getOrdenLaboratorio(){

            try {
                /*
                this.$v.fecha.$touch();
           
                if (this.$v.fecha.$invalid) {
                    return;
                }

                */

                //this.LoaderSpinnerShow();
                
                this.pagination.params.fecha= this.fecha;
          
                const response = await ordenTrabajoService.index(this.pagination.search,this.pagination);
                this.ordenResultados= response.data;
                console.log(response.data);

                this.pagination.data = response.data.data;
                this.pagination.current_page  = response.data.current_page;
                this.pagination.total = response.data.total;
              //  this.LoaderSpinnerHide();
            } catch (error) {
                console.log(error);
              //  this.LoaderSpinnerHide();
                Toast.fire({
                    icon: "error",
                    title: "Ha ocurrido un error al procesar la solicitud",
                });
            }
      
        },
        changePage(page) {
            this.pagination.page = page;
            this.getOrdenLaboratorio();
        },
        perPageChange(per_page) {
            this.pagination.per_page = per_page;
            this.pagination.page = 1;
            this.getOrdenLaboratorio();
        },
        search(query){
            this.pagination.search = query;
            this.pagination.page = 1;
            this.getOrdenLaboratorio();
        },
        imprimir(id){
            window.open(ordenTrabajoService.imprimir(id));
        }
    },
   
}
</script>
